import React from 'react'

const Precipitation = (props) => {

  return (
    <>
      {( () => {
        switch (props.State) {
        case 2:
          return "???";
        case 3:
          return <span className="prop"><img src="/img/precipitation.png" alt="Осадки"></img>Осадки</span>
        case 4:
          return <span className="prop"><img src="/img/sunlight.png" alt="Нет осадков"></img>Нет осадков</span> 
        default:
          return
        }
      })()}   
    </>
  )
}

export default Precipitation;