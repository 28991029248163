import React, { useState, useEffect } from 'react';
import Sensor from '../Sensor';
import Online from './Online';
import Door from './Door';
import Light from './Light';
import Tools from './Tools';
import Precipitation from './Precipitation';
import { Link } from 'react-router-dom';
import s from './box.module.css';

function Box(props) {

    const [state, setState] = useState(props);

    useEffect(() => {
        window.ws.on(WSMsg.bind(this));

        return function Unmount() {
            window.ws.off(WSMsg);
        }
    }, []);


    let WSMsg = (data) => {
        var obj = JSON.parse(data);

        switch (obj.t) {
        case "BoxState":
            if (obj.i === state.ID) {
                // console.debug("Box:", obj);                
                setState(obj.d);
            }
            break;

        default:
            // console.log("WS recive:", data);
        }
    }

    var boxClass = s.BoxOffline;
    if (state.Online === 3) {
        if (state.Door === 3) 
            boxClass = s.BoxOpen;
        else if (state.Online === 3) 
            boxClass = s.BoxOnline;
    }
        
    return (
        <Link to={`/box/${state.ID}`} className={s.Box+" "+boxClass}>
            <img src="/img/box.png" alt="box"></img>
            <div className={s.BoxContent}>
                <span className={s.Title}>{state.Title}</span>
                <Sensor val={state.Temperature} img="temp.png" unit="℃"/>
                <Precipitation State={state.WSPrecipitation}/>
                <Online State={state.Online} />
                <Door State={state.Door} />
                <Tools State={state.Tools}/>
                <Light State={state.Light}/>                    
            </div>            
        </Link>     
    )    
}

export default Box;