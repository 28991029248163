import React from 'react';
import Sensor from '../Sensor';
import s from './style.module.css';

class WeatherPanel extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            temp: null,
            pres: null,
            hum: null,
            light: null,
            rain: null,
            snow: null,
            wDeg: null,
            wSpeed: null,
        };
    }


    componentDidMount() {
        window.ws.on(this.WSMsg.bind(this));
    }

    componentWillUnmount() {
        window.ws.off(this.WSMsg);
    }

    WSMsg(data){
        var obj = JSON.parse(data);

        switch (obj.t) {
        case "Weather":
            this.setState({
                temp: obj.d.temp,
                pres: obj.d.pres,
                hum: obj.d.hum,
                light: obj.d.light,
                rain: obj.d.rain,
                snow: obj.d.snow,
                wDeg: obj.d.wind.deg,
                wSpeed: obj.d.wind.speed,
            });
            break;

        default:
           // console.log("WS recive:", data);
        }
    }

    render() {
        var cloudi = null;
        if (this.state.rain !== 0) {
            cloudi = <Sensor img="drop.png" val={this.state.rain} unit="мм" />
        }
        if (this.state.snow !== 0) {
            cloudi = <Sensor img="snowflake.png" val={this.state.snow} unit="мм" />
        }

        var deg = null
        switch (true) {
        case this.state.wDeg == null:
            break;            
        case this.state.wDeg >= 360 || this.state.wDeg <= 21:
            deg = "С";
            break;
        case this.state.wDeg >= 22 && this.state.wDeg <= 44:
            deg = "ССВ";
            break;
        case this.state.wDeg >= 45 && this.state.wDeg <= 66:
            deg = "СВ";
            break;
        case this.state.wDeg >= 67 && this.state.wDeg <= 89:
            deg = "ВCВ";
            break;
        case this.state.wDeg >= 90 && this.state.wDeg <= 111:
            deg = "В";
            break;
        case this.state.wDeg >= 112 && this.state.wDeg <= 134:
            deg = "ВЮВ";
            break;
        case this.state.wDeg >= 135 && this.state.wDeg <= 156:
            deg = "ЮВ";
            break;
        case this.state.wDeg >= 157 && this.state.wDeg <= 179:
            deg = "ЮЮВ";
            break;
        case this.state.wDeg >= 180 && this.state.wDeg <= 201:
            deg = "Ю";
            break;
        case this.state.wDeg >= 202 && this.state.wDeg <= 224:
            deg = "ЮЮЗ";
            break;
        case this.state.wDeg >= 225 && this.state.wDeg <= 246:
            deg = "ЮЗ";
            break;
        case this.state.wDeg >= 247 && this.state.wDeg <= 269:
            deg = "ЗЮЗ";
            break;
        case this.state.wDeg >= 270 && this.state.wDeg <= 291:
            deg = "З";
            break;
        case this.state.wDeg >= 292 && this.state.wDeg <= 314:
            deg = "ЗСЗ";
            break;
        case this.state.wDeg >= 315 && this.state.wDeg <= 336:
            deg = "СЗ";
            break;
        case this.state.wDeg >= 337 && this.state.wDeg <= 359:
            deg = "ССЗ";
            break;   
        default:
            deg = "???";
            break;
        }

        var wVal = null;
        
        if (deg !== null) {
            wVal = deg+" — "+this.state.wSpeed;
        }

        return (
            <div className={s.WeatherPanel}>
                <Sensor val={this.state.temp} img="temp.png" unit="℃" />
                <Sensor val={this.state.pres} img="gauge.png" unit="kPa" />
                <Sensor val={this.state.hum} img="humidity.png" unit="%" />
                <Sensor val={this.state.light} img="light.png" unit="л" />
                <Sensor val={wVal} img="wind.png" unit="м/с" />
                {cloudi}                
            </div>
        )
    }
}

export default WeatherPanel;