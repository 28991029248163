import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, Link } from 'react-router-dom';

import SesContext from '../../Context';
import ArrowsControl from './ArrowsControl';
import ArrowGrps from './ArrowGrps';
import BoxGrps from './BoxGrps';


function MainPage() {

    let navigate = useNavigate();

    const ses = useContext(SesContext);

    const [Loading, SetLoading] = useState(true);
    const [Arrows, SetArrows] = useState(null);
    const [Boxs, SetBoxs] = useState(null);

    useEffect(() => {
        window.ws.on( WSMsg.bind(this) );

        if (window.ws.isConnected) {
            window.ws.send({t:"FullState"});
        }

        window.ws.onStateChange((online) => {
            console.log("WS connect:", online);
            if (online) {
                window.ws.send({t:"FullState"});
            }
        });

        return function Unmount() {
            window.ws.off(WSMsg);
        }
    }, []);


    let WSMsg = (data) => {

        var obj = JSON.parse(data);

        switch (obj.t) {
        case "token":
            window.token = obj.v;
            ses.SetSes({User: obj.u, Grp: obj.g});
            break;

        case "FullState":
            SetArrows(obj.Arrows);
            SetBoxs(obj.Boxs);            
            SetLoading(false);            
            break;

        case "NideLogin":
            //console.debug("NideLogin:", obj);
            navigate("/login");            
            break;

        default:
           // console.log("WS recive:", data);
        }
    }

    return (
        <div>
            <Link to="/journal"><button type="button">Журнал</button></Link>
            <hr/>            
            <h1>Стрелки</h1>
            <ArrowsControl />            
            <ArrowGrps List={Arrows} loading={Loading} />
            <hr/>
            <h1>ШУО</h1>
            <BoxGrps List={Boxs} loading={Loading}/>
        </div>
    );
}

export default MainPage;