import React from 'react';
import s from './box.module.css';
import Box from './Box';

const BoxGrp = (props) => {

    if (props.Itms === null) {
        return <div>Нет данных</div>
    };

    return (
        <div className={s.BoxGrp}>
            <div>{props.Title}</div>
            <div className={s.BoxGrpContent}>
                {props.Itms.map((itm, i) =>
                    <Box key={i} {...itm} /> 
                )}
            </div>
        </div>
    );
}

export default BoxGrp;