import React from 'react';
import ArrowChan from './ArrowChan.js';
import s from './chans.module.css';

const ArrowChans = (props) => {

    if (!props.List) {
        return
    }
        
    return (
        <div className={s.Chans} >
            {props.List.map((itm, i) => 
                <ArrowChan key={i} {...itm} />
            )}
        </div>
    )
}

export default ArrowChans;