import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import s from './style.module.css';

function LoginPage() {

    let navigate = useNavigate();

    const [user, setUser] = useState('');
    const [pass, setPass] = useState('');

    useEffect(() => {
        window.ws.on( WSMsg.bind(this) );

        window.ws.onStateChange((online) => {
            console.log("WS connect:", online);
            // if (online) {
            //     window.ws.send({t:"FullState"});
            // }
        });

        return function Unmount() {
            window.ws.off(WSMsg);
        }
    }, []);

    let WSMsg = (data) => {
        var obj = JSON.parse(data);

        switch (obj.t) {
        case "token":
            console.log("Get token:", obj.v);

            window.token = obj.v;              
            navigate("/");
            
            break;

        default:
           // console.log("WS recive:", data);
        }
    }

    let onSubmit = (event) => {
        event.preventDefault();
        window.ws.send({t:"singin", user: user, pass: pass})
    }

    return (
        <div className={s.wrapper}>
            <h1>Вход</h1>
            <form onSubmit={onSubmit}>
                <div>             
                    <input type="text" name="user" value={user} onChange={e => setUser(e.target.value)} placeholder="Пользователь" required/>
                </div>
                <div>
                    <input type="password" name="pass" value={pass} onChange={e => setPass(e.target.value)} placeholder="Пароль"/>
                </div>
                <div>                    
                    <button type="submit">Войти</button>
                </div>
            </form>
        </div>
    );
}

export default LoginPage;