import React from 'react';
import s from './style.module.css';

const AlarmState = (props) => {

    if (props.Val) {
        return <span className={s.Alarm}>Тревога</span>
    }

    return
}

export default AlarmState