import React from 'react';
import s from './arrow.module.css';
import ArowGrp from './ArrowGrp';

const ArrowGrps = (props) => {

    if (props.loading) {
        return <div>Заргузка...</div>
    }

    if (props.List === null || props.List.length === 0) {
        return <div>Нет данных</div>
    };

    return (
        <div className={s.ArrowGrps}>
            {props.List.map((itm, i) =>
                <ArowGrp ws={props.ws} key={i} {...itm} />
            )}
        </div>
    )
}

export default ArrowGrps;