import React from 'react'
import BoxGrp from './BoxGrp';
import s from './box.module.css';

const BoxGrps = (props) => {

    if (props.loading) {
        return <div>Заргузка...</div>
    }

    if (props.List === null) {
        return <div>Нет данных</div>
    };

    return (
        <div className={s.BoxGrps}>
            {props.List.map((itm, i) =>
                <BoxGrp key={i} {...itm} />
            )}
        </div>
    );
}

export default BoxGrps;