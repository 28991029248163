import './App.css';
import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Reconnect from './components/Reconnect/Reconnect';
import TopPanel from './components/TopPanel/topPanel';
import LoginPage from './components/LoginPage/index';
import MainPage from './components/MainPage/index';
import BoxPage from './components/BoxPage/index';
import ArrowPage from './components/ArrowPage/index';
import BoxSettingPage from './components/BoxSettingPage/index';
import BoxThresholdPage from './components/BoxThresholdPage/index';
import Console from './components/Journal/journal';
import GrapshPage from './components/Grapsh/index';
import SesContext from './Context';

function App() {

  const [Offline, setOffline] = useState();
  const [Ses, SetSes] = useState({User: '', Grp: ''});

  useEffect(() => {
    window.ws.onStateChange((online) => {
      setOffline(!online);
    });

    setOffline(!window.ws.isConnected)
  }, []);

  return (
    <div className="App">
      <SesContext.Provider value={{Ses, SetSes}}>
        <BrowserRouter>
          <TopPanel />          
          <Routes>            
            <Route path="/" element={<MainPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/box/:id" element={<BoxPage />} />
            <Route path="/arrow/:id" element={<ArrowPage />} />
            <Route path="/boxOptions/:id" element={<BoxSettingPage />} />
            <Route path="/boxThreshold/:id" element={<BoxThresholdPage />} />
            <Route path="/journal" element={<Console />} />
            <Route path="/grapsh/:trg/:id/:val" element={<GrapshPage />} />
            <Route path="/grapsh/:trg/:id/:val/:aid/:cid" element={<GrapshPage />} />
          </Routes>
        </BrowserRouter>        
      </SesContext.Provider>

      <Reconnect show={Offline}>
        Соединение с ПКО...
      </Reconnect>
    </div>
  );

}

export default App;
