import React from 'react';
import s from './arrow.module.css';
import Arrow from './Arrow';

const ArrowGrp = (props) => {

    // var cnt = 0;
    // if (props.Itms) {
    //     for (let i = 0; i <  props.Itms.length; i++) {
    //         if ( props.Itms[i].State >= 2) {
    //             cnt++;
    //         }
    //     };
    // }
    // if (cnt === 0) {
    //     return <div>Нет данных</div>
    // }

    if (props.Itms === null || props.Itms.length === 0) {
        return <div>Нет данных</div>
    };

    return (
        <div className={s.ArrowGrp}>
            <div>{props.Title}</div>
            <div className={s.ArrowGrpContent}>
                {props.Itms.map((itm, i) =>
                    <Arrow key={i} {...itm} /> 
                )}
            </div>
        </div>
    )
}

export default ArrowGrp;