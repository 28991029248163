import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import ArrowBtns from '../ArrowBtns';
import ArrowChans from './ArrowChans';
import AlarmState from '../Alarm.js';
import Heat from '../Heat.js';
import Sensor from '../Sensor';
import ChanState from './ChanState.js';

function ArrowPage(props) {

    const [state, setState] = useState({
        ID: props.params.id,
    });

    useEffect(() => {

        let { id } = props.params;

        window.ws.on(WSMsg.bind(this));

        if (window.ws.isConnected) {
            window.ws.send({t:"ArrowState", i: id });
        }

        window.ws.onStateChange((online) => {
            console.log("WS connect:", online);
            if (online) {
                window.ws.send({t:"ArrowState", i: id });
            }
        });
        return function Unmount() {
            window.ws.off(WSMsg);
        }
    }, []);

    let WSMsg = (data) => {
        var obj = JSON.parse(data);

        switch (obj.t) {
        case "ArrowState":
            if (obj.i === state.ID) {
                //console.debug("Box:", obj);
                setState(obj.d);
            }

            break;

        default:
            // console.log("WS recive:", data);
        }
    }

    var Temp = Math.round(state.Temperature*10)/10;;

    return (
        <div>
            <Link to="/"><button type="button">Главная</button></Link>
            <hr/>
            <ArrowBtns state={state.State} id={state.ID}/>
            <h1>{state.Title}</h1>
            <table className='table'>
                <thead>
                    <tr>
                        <th>Название</th>                            
                        <th>Значение</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Температура:</td>                            
                        <td>
                            <Link to={`/grapsh/arrow/${state.ID}/Temperature?title=${state.Title}&sens=Температура&meas=℃`}>
                                {Temp}℃
                            </Link>
                        </td>
                    </tr>
                    <tr>
                        <td>Ток:</td>                            
                        <td>
                            <Link to={`/grapsh/arrow/${state.ID}/Current?title=${state.Title}&sens=Ток&meas=A`}>
                                <Sensor val={state.Current} unit="А" digs="1"/>
                            </Link>
                        </td>
                    </tr>
                    <tr>
                        <td>Состояние:</td>                            
                        <td>
                            <Link to={`/grapsh/arrow/${state.ID}/State?title=${state.Title}&sens=Состояние`}>
                                <ChanState val={state.State} /> 
                            </Link>
                        </td>
                    </tr>
                    <tr>
                        <td>От:</td>                            
                        <td>{state.StateFrom}</td>
                    </tr>
                    <tr>
                        <td>Нагрев:</td>
                        <td>
                            <Link to={`/grapsh/arrow/${state.ID}/Heat?title=${state.Title}&sens=Нагрев`}>
                                <Heat val={state.Heat}/>
                            </Link>
                        </td>
                    </tr>
                    <tr>
                        <td>Авария:</td>
                        <td><AlarmState Val={state.SumAlarm}/></td>
                    </tr>
                    <tr>
                        <td>Авария температуры:</td>                            
                        <td><AlarmState Val={state.TempAlarm}/></td>
                    </tr>
                    <tr>
                        <td>Критическая ошибка:</td>                            
                        <td><AlarmState Val={state.CritError}/></td>
                    </tr>                        
                </tbody>                    
            </table>
            
            <ArrowChans List={state.Itms} /> 
        </div>
    );  
}

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

export default withParams(ArrowPage);