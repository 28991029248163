import React, { useState, useEffect } from 'react';
import Sensor from '../Sensor';
import Heat from '../Heat';
import { Link } from 'react-router-dom';
import ArrowBtns from '../ArrowBtns';
import s from './arrow.module.css';

function Arrow(props) {

    const [state, setState] = useState(props);

    useEffect(() => {
        window.ws.on(WSMsg.bind(this));

        return function Unmount() {
            window.ws.off(WSMsg);
        }
    }, []);

    let WSMsg = (data) => {
        var obj = JSON.parse(data);

        switch (obj.t) {
        case "ArrowState":
            if (obj.d.ID === state.ID) {
                //console.debug("Arrow:", obj);
                setState(obj.d);
            }
            break;

        default:
            // console.log("WS recive:", data);
        }
    }
           
    var AClass = s.ArrowOffline;
    if (!(state.State === 0 || state.State === 1)) { 
        AClass = s.ArrowOnline;
    }

    if (state.SumAlarm || state.TempAlarm || state.CritError) {
        AClass = s.ArrowError;
    }
    // } else if (state.Heat){
    //     AClass = s.ArrowHeat;
    // }
        
    return (
        <Link to={`/arrow/${state.ID}`} className={s.Arrow+" "+AClass}>
            <img src="/img/arrow.png" alt="box"></img>
            <div className={s.ArrowContent}>
                <span className={s.Title}>{state.Title}</span>                    
                <Sensor val={state.Temperature} img="temp.png" unit="℃" digs="1"/>
                <Heat val={state.Heat}/>                    
                <ArrowBtns state={state.State} id={state.ID}/>                    
            </div>            
        </Link>
    )

}

export default Arrow;