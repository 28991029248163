import React from 'react';
import s from './chans.module.css';

const ChanState = (props) => {

    switch (props.val) {
        case 0:
            return <span>---</span>
            
        case 1:
            return <span className={s.ChanOutOfOrder}>Не обслуживается</span>
            
        case 2:
            return <span className={s.ChanOff}>Выкл</span>
            
        case 3:
            return <span className={s.ChanOn}>Вкл</span>
            
        case 4:
            return <span className={s.ChanAuto}>Авто</span>
            
        case 5:
            return <span className={s.ChanMix}>***</span>
        default:
            return <span className={s.ChanErr}>???</span>
    }
}

export default ChanState;
