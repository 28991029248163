import React, { useState, useEffect } from 'react'; // useContext
import { Link, useParams, useLocation } from 'react-router-dom';
import CanvasJSReact from '@canvasjs/react-charts';
//import SesContext from '../../Context';

var CanvasJSChart = CanvasJSReact.CanvasJSChart;

function GrapshPage(props) {

    const query = new URLSearchParams(useLocation().search);

    //const {Ses, SetSes} = useContext(SesContext);

    const [data, setData] = useState(null);
    const [gw, SetGW] = useState("24h");

    const refresh = () => {

        if (!window.ws.isConnected) {
            return;
        }
        
        let { trg, id, val, aid, cid } = props.params;

        window.ws.send({
            gw: gw,
            t: "Grapsh", 
            trg: trg, 
            id: id, 
            val: val, 
            aid: aid, 
            cid: cid,
        });
    }

    useEffect(() => {     
        
        refresh();

        window.ws.on(WSMsg.bind(this));
        
        window.ws.onStateChange((online) => {
            console.log("WS connect:", online);
            refresh();
        });

        return function Unmount() {
            window.ws.off(WSMsg);
        }
    }, [gw]);

    let WSMsg = (data) => {
        var obj = JSON.parse(data);

        switch (obj.t) {
        case "Grapsh":
            setData( obj.d );
            break;

        default:
            //console.log("WS recive:", obj.t);
        }
    }

    const handleGWChange = (event) => {
        const { value } = event.target;
        SetGW(value);
    };

    var backURL = "/"+props.params.trg+"/"+props.params.id;
    var title = query.get('title')+" - "+query.get('sens');
    var meas = query.get('meas') || "";

    if (props.params.trg === "chan") {
        backURL = "/arrow/"+props.params.aid;
        title = "Канал: "+props.params.cid;
    }

    const options = {        
        title: {
            text: ""
        },
        axisX:{
            valueFormatString: "HH:mm:ss",
        },
        axisY:{
            suffix: meas,  
        },
        data: [{
            type: "line",
            xValueType: "dateTime",
            // xValueFormatString: "MMM YYYY",
            // yValueFormatString: "#,##0.00",
            dataPoints: data,
        }]         
    }

    return (
        <div>
            <Link to={backURL}><button type="button">Назад</button></Link>
            <hr/>
            <h1>{title}</h1>

            <select value={gw} onChange={handleGWChange} >
                <option value="5m">5 мин</option>
                <option value="1h">1 час</option>
                <option value="24h">24 часа</option>
                <option value="31d">1 месяц</option>
            </select>

            <CanvasJSChart options = {options} />
        </div>
    );
}

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

export default withParams(GrapshPage);