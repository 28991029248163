import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import SesContext from '../../Context';
import s from './style.module.css';

function LoginPanel() {
    const {Ses} = useContext(SesContext);

    var Head = <div className={s.HeadText}>
        {/* <img src='/img/elteza.png'alt="ЭЛТЕЗА" className={s.logo}></img> */}
        <img src='/img/mettem-m.png' alt="МЕТТЭМ-М" className={s.logo}></img>
        <p className={s.LogoTitle}>СЭСП</p>
    </div>

    if (Ses.User === "") {    
        return (
            <div className={s.HeadPanel}>
                {Head}
                
                <div className={s.LoginPanel}>
                    <Link to='/login'><button type="button">Вход</button></Link>
                </div>
            </div>
        )
    }

    return (
        <div className={s.HeadPanel}>
            {Head}
            
            <div className={s.LoginPanel}>
                <b className={s.Login}>{Ses.User}</b>
                <Link to='/login'><button type="button">Выход</button></Link>
            </div>
        </div>
    )
}

export default LoginPanel;