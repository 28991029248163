import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';

function BoxThresholdPage(props) {

    let navigate = useNavigate();

    const [state, setState] = useState({
        ID: props.params.id,
        loading: true,
    });

    useEffect(() => {
        let id = props.params.id;

        window.ws.on(WSMsg.bind(this));

        if (window.ws.isConnected) {
            window.ws.send({t:"BoxThreshold", i: id });
        }

        window.ws.onStateChange((online) => {
            console.log("WS connect:", online);
            if (online) {
                window.ws.send({t:"BoxThreshold", i: id });
            }
        });

        return function Unmount() {
            window.ws.off(WSMsg);
        }
    }, []);

    let WSMsg = (data) => {
        var obj = JSON.parse(data);

        switch (obj.t) {
        case "BoxThreshold":
            if (obj.bid === state.ID) {
                setState(obj.d);
            }

            break;

        default:
            // console.log("WS recive:", data);
        }
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        setState(() => {
          return {
            ...state,
            [name]: value,
          };
        });
    };

    if (state.loading) {        
        return <>
            <Link to={`/box/${props.params.id}`}><button type="button">Назад</button></Link>
            <hr/> <div>Заргузка...</div>
        </>
    }

    return <>
        <Link to={`/box/${props.params.id}`}><button type="button">Назад</button></Link>
        <hr/>
        <h1>{state.title}</h1>

        
        <h2>Без осадков</h2>
        <table className='table'>
            <thead>
                <tr>
                    <th></th>
                    <th>Мин.</th>
                    <th>Макс.</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
            <tr>
                <td>Рельс:</td>
                    <td><input type="number" min="-35.0" max="30.0" step="0.1" name='TRailDownCur' value={state.TRailDownCur} onChange={handleChange} /></td>
                    <td><input type="number" min="-35.0" max="30.0" step="0.1" name='TRailUpCur' value={state.TRailUpCur} onChange={handleChange} /></td>
                    <td>℃</td>
                </tr>                    
                <tr>
                    <td>Водух:</td>
                    <td><input type="number" min="-35.0" max="30.0" step="0.1" name='TAirDownCur' value={state.TAirDownCur} onChange={handleChange} /></td>
                    <td><input type="number" min="-35.0" max="30.0" step="0.1" name='TAirUpCur' value={state.TAirUpCur} onChange={handleChange} /></td>
                    <td>℃</td>
                </tr>
                
            </tbody>
        </table>
    
        <h2>С осадками</h2>
        <table className='table'>
            <thead>
                <tr>
                    <th></th>
                    <th>Мин.</th>
                    <th>Макс.</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
            <tr>
                <td>Рельс:</td>
                    <td><input type="number" min="-35.0" max="30.0" step="0.1" name='TRailSnowDownCur' value={state.TRailSnowDownCur} onChange={handleChange} /></td>
                    <td><input type="number" min="-35.0" max="30.0" step="0.1" name='TRailSnowUpCur' value={state.TRailSnowUpCur} onChange={handleChange} /></td>
                    <td>℃</td>
                </tr>                    
                <tr>
                    <td>Водух:</td>
                    <td><input type="number" min="-35.0" max="30.0" step="0.1" name='TAirSnowDownCur' value={state.TAirSnowDownCur} onChange={handleChange} /></td>
                    <td><input type="number" min="-35.0" max="30.0" step="0.1" name='TAirSnowUpCur' value={state.TAirSnowUpCur} onChange={handleChange} /></td>
                    <td>℃</td>
                </tr>
                
            </tbody>
        </table>

        <h2>Время догрева</h2>
        <table className='table'>           
            <tbody>
            <tr>
                <td>Догрев:</td>
                    <td><input type="number" min="0" max="" step="300" name='TimeToHeartCur' value={state.TimeToHeartCur} onChange={handleChange} /></td>                       
                    <td>мин</td>
                </tr>                    
            </tbody>
        </table>

        <h2>Освещенность</h2>
        <table className='table'>           
            <tbody>
            <tr>
                <td>Освещенность:</td>
                    <td><input type="number" min="0" max="" step="5000" name='LigthBourderCur' value={state.LigthBourderCur} onChange={handleChange} /></td>                       
                    <td>Люкс</td>
                </tr>                    
            </tbody>
        </table>

        <button onClick={(e) => {   
                e.preventDefault();
                window.ws.send({
                    t: "BoxThresholdSet", 
                    i: props.params.id,
                    d: state,
                });

                navigate("/box/"+props.params.id);

            }}>Сохранить</button>
    </>
}

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

export default withParams(BoxThresholdPage);