import React from 'react';
import s from './style.module.css';

const CurAlarm = (props) => {

    switch (props.Val) {
    case 0:
		return
	case 1:
		return <span className={s.Alarm}>Авария токового трансформатора (отсутствует измеритель тока)</span>
	case 2:
		return <span className={s.Alarm}>Отключен автомат или обрыв первичной обмотки силового трансформатора</span>
	case 3:
		return <span className={s.Alarm}>Нагрузка меньше номинальной</span>
	case 4:
		return <span className={s.Alarm}>Перегрузка по току</span>
	case 5:
		return <span className={s.Alarm}>Короткое замыкание симистора или кз первичной обмотки силового трансформатора</span>
        // идет потребление тока со снятым управляющим сигналом  с симмистора
	case 6:
		return <span className={s.Alarm}>Обрыв нагрузки или неисправность силового ключа</span>
        // при подаче управляющего сигнала на симмистр не ток в нагрузке не увеличивается
	case 7:
		return <span className={s.Alarm}>Отключен вводной контактор в нормальном режиме</span>
        // не является аварией
	case 8:
		return <span className={s.Alarm}>Включен ручной инструмент</span>
        
	default:
		return <span className={s.Alarm}>Error state: {props.Val}</span>
    }
}

export default CurAlarm