import React,  { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';

function Journal(){

    const [Loading, SetLoading] = useState(true);
    const [Journal, SetJournal] = useState(null);    

    useEffect(() => {
        window.ws.on( WSMsg.bind(this) );

        if (window.ws.isConnected) {
            window.ws.send({t:"Journal"});
        }

        window.ws.onStateChange((online) => {
            console.log("WS connect:", online);
            if (online) {
                window.ws.send({t:"Journal"});
            }
        });

        return function Unmount() {
            window.ws.off(WSMsg);
        }
    }, []);


    let WSMsg = (data) => {
        var obj = JSON.parse(data);

        switch (obj.t) {
        case "Journal":
            SetJournal(obj.d);
            SetLoading(false);
            break;

        default:
           // console.log("WS recive:", data);
        }
    }

    if (Loading) {
        return (
            <>
                <Link to="/"><button type="button">Главная</button></Link>
                <hr/>
                <div>
                    Загрузка...
                </div>
            </>
        );
    }

    if (!Journal) {
        return (
            <>
                <Link to="/"><button type="button">Главная</button></Link>
                <hr/>
                <div>
                    Нет данных...
                </div>
            </>
        );
    }

    return (
        <div>
            <Link to="/"><button type="button">Главная</button></Link>
            <hr/>
            <table className='table'>
                <thead>
                    <tr>
                        <th>Время</th>
                        <th>Источник</th>
                        <th>Состояние</th>
                    </tr>
                </thead>
                <tbody>
                    {Journal.map((itm, i) => 
                        <tr key={i} >
                            <td>{itm.time}</td>
                            <td>{itm.from}</td>
                            <td>{itm.state}</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
}

export default Journal;