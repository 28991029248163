import React from 'react'

const Online = (props) => {

    return (
      <>
        {( () => {
          switch (props.State) {
          case 3:
            return <span className="prop"><img src="/img/online.png" alt="В сети"></img>В сети</span>  
          case 4:
            return <span className="prop"><img src="/img/offline.png" alt="Обрыв"></img>Обрыв</span>
          default:
            return
          }
        })()}   
      </>
    )
}

export default Online;