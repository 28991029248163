import React from 'react'

const Sensor = (props) => {

    var tImg = "";
    if (props.img) {
      var img = "/img/"+props.img;
      tImg = <img src={img} alt="box"></img>;
    }

    if (props.val === null) {
      return
    }

    if (props.val === 65535 || props.val === 999) {
      return (
        <div className="prop">{tImg}---</div>
      )
    }

    var val = props.val;
    if (props.digs) {
      val = Math.round(val * 10) / 10
    }
    
    if (props.unit) {
      val = val+" "+props.unit;
    }

    return (
        <div className="prop">
          {tImg}<span>{val}</span>
        </div>
    )
}

export default Sensor;