import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';

function BoxSettingPage(props) {

    let navigate = useNavigate();

    const [state, setState] = useState({
        ID: props.params.id,
        loading: true,
    });

    useEffect(() => {
        let id = props.params.id;

        window.ws.on(WSMsg.bind(this));

        if (window.ws.isConnected) {
            window.ws.send({t:"BoxSettings", i: id });
        }

        window.ws.onStateChange((online) => {
            console.log("WS connect:", online);
            if (online) {
                window.ws.send({t:"BoxSettings", i: id });
            }
        });

        return function Unmount() {
            window.ws.off(WSMsg);
        }
    }, []);

    let WSMsg = (data) => {
        var obj = JSON.parse(data);

        switch (obj.t) {
        case "BoxSettings":
            if (obj.bid === state.ID) {                
                setState(obj.d)                
            }

            break;

        case "ArrowState":
           
            if (obj.d.BID === state.ID && obj.d.Itms != null) {
                for (let i=0; i < obj.d.Itms.length; i++) {
                    let itm = obj.d.Itms[i];   

                    setState( (prevState) => ({
                        ...prevState,
                        ["NormalVal"+itm.CID]: itm.Current,
                    }) )
                }
            }

            break;

        default:
            // console.log("WS recive:", data);
        }
    }

    if (state.loading) {
        return <>
            <Link to={`/box/${props.params.id}`}><button type="button">Назад</button></Link>
            <hr/> <div>Заргузка...</div>
        </>
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        setState(() => {
          return {
            ...state,
            [name]: value,
          };
        });
    };

    const sendOptions = (e) => {
        e.preventDefault();

        window.ws.send({
            t: "BoxSettingsSet", 
            i: props.params.id,
            d: state,
        });

        navigate("/box/"+props.params.id);
    }

    // Arrow
    const ArrowTitle = [];
    const ChansTitle = [];
    const ArrowItms = [];
    // const RailItms = [];
    const ChansItms = [];
    const NormaItems = [];

    for (let i=0; i<state.RowCnt; i++){
        ArrowTitle.push(<th key={i}>{i+1}</th>)
        ArrowItms.push(
            <td key={i}>
                <select name={`Arrow${i}`} value={state[`Arrow${i}`]} onChange={handleChange} >
                    <option value="0">Off</option>
                    <option value="1">On</option>
                </select>
            </td>
        )        
        // RailItms.push(
        //     <td key={i}>
        //         <input type="number" name={`Rail${i}`} value={state[`Rail${i}`]} min="1" max={state.RowCnt} onChange={handleChange} />
        //     </td>
        // )
    }

    for (let i=0; i<state.RowChan; i++){
        ChansTitle.push(<th key={i}>{i+1}</th>)
        ChansItms.push(
            <td key={i}>
                <input type="number" name={`Chan${i}`} value={state[`Chan${i}`]} min="1" max={state.RowCnt} onChange={handleChange} />
            </td>
        )
        NormaItems.push(
            <tr key={i}>
                <td>{i+1}</td>
                <td>{state[`NormalVal${i}`]}</td>
                <td><input type="number" name={`Normal${i}`} value={state[`Normal${i}`]} min="1" max={state.NormCurrentMax} step="0.1" onChange={handleChange} /></td>
                <td>А</td>
            </tr>
        )
    }

    return <>
        <Link to={`/box/${props.params.id}`}><button type="button">Назад</button></Link>
        <hr/>
            <h1>{state.title}</h1>
            
            <div>
                <h2>Стрелки</h2>
                <table className='table'>
                    <thead>
                        <tr>{ArrowTitle}</tr>
                    </thead>
                    <tbody>
                        <tr>{ArrowItms}</tr>
                    </tbody>
                </table>
            </div>

            {/* <div>
                <h2>Датчики температуры к стрелкам</h2>
                <table className='table'>
                    <thead>
                        <tr>{ArrowTitle}</tr>
                    </thead>
                    <tbody>
                        <tr>{RailItms}</tr>
                    </tbody>
                </table>
            </div> */}

            <div>
                <h2>Каналы к стрелкам</h2>
                <table className='table'>
                    <thead>
                        <tr>{ChansTitle}</tr>
                    </thead>
                    <tbody>
                        <tr>{ChansItms}</tr>
                    </tbody>
                </table>
            </div>
                        
            <div>
                <h2>Нормальная нагрука</h2>

                <em>Чтобы данные были актуальные, включите все стрелки в режим "Вкл"</em>
                <table className='table'>
                    <thead>
                        <tr>
                            <th>Канал</th>
                            <th>Текущее</th>
                            <th>Порог</th>
                            <th>Ед. изм.</th>
                        </tr>
                    </thead>
                    <tbody>               
                        {NormaItems}
                    </tbody>
                </table>
                <button onClick={(e) => {   
                    e.preventDefault();
                    
                    let s = state;
                    for (let i=0; i<state.RowChan; i++){
                        s[`Normal`+i] = s[`NormalVal`+i]
                    }
                    setState(s)

                }}>Взять текущую нагрузку</button>
            </div>

            <table className='table'>
                <tbody>
                    <tr>
                        <td>PIN</td>
                        <td><input type="number" name="Pin" min="0" max="65535" value={state.Pin} onChange={handleChange}/></td>
                        <td><button onClick={sendOptions}>Соранить</button></td>
                    </tr>
                </tbody>
            </table>
    </>    
}

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}


export default withParams(BoxSettingPage);