import React, { useState, useEffect, useContext } from 'react';
import { Link, useParams } from 'react-router-dom';
import { StateToStr } from '../utils';
import SesContext from '../../Context';
import Sensor from '../Sensor';

function BoxPage(props) {

    const {Ses} = useContext(SesContext);

    const [state, setState] = useState({
        ID: props.params.id,
    });

    useEffect(() => {
        let { id } = props.params;

        window.ws.on(WSMsg.bind(this));

        if (window.ws.isConnected) {
            window.ws.send({t:"BoxState", i: id });
        }

        window.ws.onStateChange((online) => {
            console.log("WS connect:", online);
            if (online) {
                window.ws.send({t:"BoxState", i: id });
            }
        });

        return function Unmount() {
            window.ws.off(WSMsg);
        }
    }, []);

    let WSMsg = (data) => {
        var obj = JSON.parse(data);

        switch (obj.t) {
        case "BoxState":
            if (obj.i === state.ID) {
                //console.debug("Box:", obj);
                setState(obj.d);
            }

            break;

        default:
            // console.log("WS recive:", data);
        }
    }
    
    var ThresholdDiv = "";
    if (Ses.Grp === "operator" || Ses.Grp === "admin") {
        ThresholdDiv = <div>
            <Link to={`/boxThreshold/${state.ID}`}>
                <button type="button">Пороги срабатывания</button>
            </Link>
            <br/>
        </div>
    }
   
    var OptionsDiv = "";
    if (Ses.Grp === "admin") {
        OptionsDiv = <Link to={`/boxOptions/${state.ID}`}><button type="button">Настройки</button></Link>
    }

    return (
        <div>
            <Link to="/"><button type="button">Главная</button></Link>
            <hr/>
            <h1>{state.Title}</h1>

            <table className='table'>
                <thead>
                    <tr>
                        <th>Название</th>
                        <th>Значение</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Адресс:</td>
                        <td>{state.Address}</td>
                    </tr>
                    <tr>
                        <td>Серийный номер:</td>
                        <td>{state.SerialNumber}</td>
                    </tr>
                    <tr>
                        <td>Версия программы:</td>
                        <td>{state.SoftwareVer}</td>
                    </tr>
                    <tr>
                        <td>Дата производства:</td>
                        <td>{state.ManufacturedDate}</td>
                    </tr>
                    <tr>
                        <td>Время наработки:</td>
                        <td>{state.TotalUptimeS}</td>
                    </tr>
                    <tr>
                        <td>Время работы:</td>
                        <td>{state.UptimeS}</td>
                    </tr>
                    <tr>
                        <td>В сети:</td>
                        <td>
                            <Link to={`/grapsh/box/${state.ID}/Online?title=${state.Title}&sens=В сети`}>
                                {StateToStr(state.Online, "Да", "Нет")}
                            </Link>
                        </td>
                    </tr>
                    <tr>
                        <td>Ток:</td>
                        <td>
                            <Link to={`/grapsh/box/${state.ID}/Current?title=${state.Title}&sens=Ток&meas=А`}>                            
                                <Sensor val={state.Current} unit="А" digs="1"/>
                            </Link>                            
                        </td>
                    </tr>
                    <tr>
                        <td>Темп. ШУПС:</td>
                        <td>
                            <Link to={`/grapsh/box/${state.ID}/AirTempBox?title=${state.Title}&sens=Темп. ШУПС&meas=℃`}>
                                <Sensor val={state.TemperatureBox} unit="℃" />
                            </Link>
                        </td>
                    </tr>
                    <tr>
                        <td>Температура:</td>
                        <td>
                            <Link to={`/grapsh/box/${state.ID}/AirTempOutSide?title=${state.Title}&sens=Температура&meas=℃`}>
                                <Sensor val={state.Temperature} unit="℃" />
                            </Link>
                        </td>
                    </tr>
                    <tr>
                        <td>Осадки:</td>
                        <td>
                            <Link to={`/grapsh/box/${state.ID}/Precipitation?title=${state.Title}&sens=Осадки`}> 
                                {StateToStr(state.WSPrecipitation, "Есть", "Нет")}
                            </Link>
                        </td>
                    </tr>
                    <tr>
                        <td>Ручной инструмент:</td>
                        <td>{StateToStr(state.Tools)}</td>
                    </tr>
                    <tr>
                        <td>Освещение:</td>
                        <td>{StateToStr(state.Light)}</td>
                    </tr>
                    <tr>
                        <td>Дверь открыта:</td>                        
                        <td>
                            <Link to={`/grapsh/box/${state.ID}/DoorOpen?title=${state.Title}&sens=Дверь`}>
                                {StateToStr(state.Door, "Да", "Нет")}
                            </Link>
                        </td>
                    </tr>
                </tbody>
            </table>

            {ThresholdDiv}
            {OptionsDiv}
        </div>
    );    
}

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

export default withParams(BoxPage);