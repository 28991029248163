import React, { useContext } from 'react'
import SesContext from '../Context';
import s from './style.module.css';
import { ChanStateToStr } from './utils';

function ArrowBtns(props) {

    const {Ses} = useContext(SesContext);

    if (Ses.Grp !== "admin" && Ses.Grp !== "operator") {

        var img = <img src="/img/switch.png" alt="box"></img>
        var state = ChanStateToStr(props.state);

        return <div className="prop">{img}{state}</div>
    }
    
    return (
        <div className={s.ArowControl}>
            <button className={props.state === 4 ? 'selected': ''} disabled={props.state === 0 || props.state === 1} onClick={(e) => {   
                e.preventDefault();
                window.ws.send({t:"ArrowStateSet", i: props.id, d:4});

            }} >Авто</button>
            <button className={props.state === 3 ? 'selected': ''} disabled={props.state === 0 || props.state === 1} onClick={(e) => {   
                e.preventDefault();
                window.ws.send({t:"ArrowStateSet", i: props.id, d:3});

            }} >Вкл</button>
            <button className={props.state === 2 ? 'selected': ''} disabled={props.state === 0 || props.state === 1} onClick={(e) => {   
                e.preventDefault();
                window.ws.send({t:"ArrowStateSet", i: props.id, d:2});

            }} >Выкл</button>
        </div>
    )
}

export default ArrowBtns;