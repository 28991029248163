import React from 'react';
import PropTypes from 'prop-types';
import s from './Reconnect.module.css';

class Reconnect extends React.Component {
  render() {
    // Render nothing if the "show" prop is false
    if(!this.props.show) {
      return null;
    }

    return (
      <div className={s.Backdrop}>
        <div className={s.Modal}>
          {this.props.children}

          <div className={s.footer}>
          </div>
        </div>
      </div>
    );
  }
}

Reconnect.propTypes = {
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool,
  children: PropTypes.node
};

export default Reconnect;