import React from 'react';
import s from './style.module.css';
import LoginPanel from './headPanel';
import WeatherPanel from './weatherPanel';

const TopPanel = (props) => {

    return (<>
            <LoginPanel />
            <div className={s.TopPanel}>
                <WeatherPanel />                
            </div>
        </>
    )
}

export default TopPanel;