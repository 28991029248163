import React from 'react'

const Door = (props) => {

    return (
      <>
        {( () => {
          switch (props.State) {
          case 3:
            return <span className="prop"><img src="/img/alarm.gif" alt="Вскрытие"></img>Вскрытие</span>
          default:
            return
          }
        })()}   
      </>
    )
}

export default Door;