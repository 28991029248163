import React from 'react'

const ManualTools = (props) => {

    return (
      <>
        {( () => {
          switch (props.State) {
          case 2:
            return "???";
          case 3:
            return <span className="prop"><img src="/img/ManualTools.png" alt="Manual tools"></img>Вкл</span>
          default:
            return
          }
        })()}   
      </>
    )
}

export default ManualTools;