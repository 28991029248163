import React from 'react'

const Heat = (props) => {

    if (props.val) {
      return (
        <div className="prop">
          <img src="/img/arrow_hot.png" alt="box"></img>
          Вкл
        </div>
      )
    }

    return (
      <div className="prop">
          <img src="/img/arrow_off.png" alt="box"></img>
          Выкл
        </div>
    )
}

export default Heat;