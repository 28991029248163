import React from 'react';
import { Link } from 'react-router-dom';
import AlarmState from '../Alarm.js';
import Heat from '../Heat.js';
import CurAlarm from '../CurAlarm.js';
import Sensor from '../Sensor';
import s from './chans.module.css';

const ArrowChan = (props) => {
    return (
        <div className={s.Chan}>
            <h2>Канал: {props.CID}</h2>
            <table className='table'>
                <thead>
                    <tr>
                        <th>Название</th>
                        <th>Значение</th>
                    </tr>
                </thead>
                <tbody>
                       <tr>
                        <td>Ток:</td>
                        <td>
                            <Link to={`/grapsh/chan/${props.BID}/Current/${props.AID}/${props.CID}?title=${props.Title}&sens=Ток&meas=A`}>
                                <Sensor val={props.Current} unit="А"/>
                            </Link>
                        </td>
                    </tr>
                    <tr>
                        <td>Нагрев:</td>
                        <td>
                            <Link to={`/grapsh/chan/${props.BID}/Heat/${props.AID}/${props.CID}?title=${props.Title}&sens=Нагрев`}>
                                <Heat val={props.Heat}/>
                            </Link>
                        </td>
                    </tr>
                    <tr>
                        <td>Авария изоляции:</td>
                        <td><AlarmState Val={props.IsolAlarm}/></td>
                    </tr>
                    <tr>
                        <td>Авария ток:</td>
                        <td><CurAlarm Val={props.CurAlarm}/></td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default ArrowChan;
