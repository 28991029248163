
function StateToStr(val, On = "Вкл", Off = "Выкл") {
    switch (val) {
    case 0:
        return "---"
    case 1:
        return "Нет присутствует"
    case 2:
        return "Присутствует, нет данных"
    case 3:
        return On
    case 4:
        return Off
    default:
        return "???"
    }
}

// function BoxStateToStr(val) {
//     switch (val) {
//     case -1:
//         return "---"
        
//     case 0:
//         return "На связи"
        
//     case 1:
//         return "Вскрытие"
        
//     case 2:
//         return "Обрыв связи"
//     default:
//         return "???"
//     }
// }

function ChanStateToStr(val) {
    switch (val) {
    case 0:
        return "---"
        
    case 1:
        return "Не обслуживается"
        
    case 2:
        return "Выкл"
        
    case 3:
        return "Вкл"
        
    case 4:
        return "Авто"
        
    case 5:
        return "***"
    default:
        return "???"
    }
}

function CurAlarmToStr(val) {
    switch (val) {
    case 0:
        return "Авария отсутствует"
    case 1:
        return "Авария токового трансформатора (отсутствует измеритель тока)"
    case 2:
        return "Отключен автомат или обрыв первичной обмотки силового трансформатора"        
    case 3:
        return "нагрузка меньше номинальной"        
    case 4:
        return "Перегрузка по току"        
    case 5:
        return "Короткое замыкание симистора или кз первичной обмотки силового трансформатора. идет потребление тока со снятым управляющим сигналом  с симмистора"            
    case 6:
        return "Обрыв нагрузки или неисправность силового ключа. при подаче управляющего сигнала на симмистр не ток в нагрузке не увеличивается"          
    case 7:
        return "Отключен вводной контактор в нормальном режиме. не является аварией"                  
    case 8:
        return "Включен ручной инструмент" 
    default:
        return "???"
    }
}

export {StateToStr, ChanStateToStr, CurAlarmToStr};