import React from 'react'

const Light = (props) => {

  return (
    <>
      {( () => {
        switch (props.State) {
        case 2:
          return "???";
        case 3:
          return <span className="prop"><img src="/img/light_on.png" alt="on"></img>Вкл</span>
        case 4:
          return <span className="prop"><img src="/img/light_off.png" alt="off"></img>Выкл</span> 
        default:
          return
        }
      })()}   
    </>
  )
}

export default Light;