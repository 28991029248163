import React, { useContext } from 'react';
import SesContext from '../../Context';

function ArrowsControl() {

    const {Ses} = useContext(SesContext);

    if (Ses.Grp !== "admin" && Ses.Grp !== "operator") {
        return
    }

    return (
        <>
            <button onClick={(e) => { e.preventDefault(); window.ws.send({t:"AllStateSet", d:4}); }}>Авто</button>
            <button onClick={(e) => { e.preventDefault(); window.ws.send({t:"AllStateSet", d:3}); }}>Вкл</button>
            <button onClick={(e) => { e.preventDefault(); window.ws.send({t:"AllStateSet", d:2}); }}>Выкл</button>        
        </>
    )
}

export default ArrowsControl